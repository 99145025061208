import React, { useEffect } from 'react';

export default function KYCVerification() {
  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    script1.src = 'https://cdn.veriff.me/sdk/js/1.5/veriff.min.js';
    script2.src = 'https://cdn.veriff.me/incontext/js/v1/veriff.js';
    script1.async = true;
    script2.async = true;
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    script2.onload = () => {
      const veriff = window.Veriff({
        host: 'https://stationapi.veriff.com',
        apiKey: '264e8d24-45ae-4453-887c-af45a190c953',
        parentId: 'veriff-root',
        onSession: function(err, response) {
          if (err) return console.error('Veriff error:', err);
          window.veriffSDK.createVeriffFrame({ url: response.verification.url });
        }
      });

      veriff.mount({
        formLabel: {
          vendorData: 'Telegram Username'
        }
      });
    };

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <div className="min-h-screen w-full bg-white">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Atmos Admirals KYC Verification
          </h1>
          <div className="max-w-2xl mx-auto">
            <p className="text-gray-600 mb-4">
              Welcome to the final step of joining the Atmos Admirals elite leadership program. Our KYC process ensures the integrity and commitment of each member, maintaining the highest standards in our DeFi ecosystem.
            </p>
            <p className="text-gray-600">
              By completing this verification, you demonstrate your dedication to our mission and help us maintain a trusted, secure community of genuine DeFi innovators.
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="bg-gray-50 rounded-xl shadow-lg p-8 mb-8 w-[500px]">
            <div id="veriff-root" className="min-h-[500px]" />
          </div>
        </div>

        <div className="text-center text-sm text-gray-500">
          Your information is securely processed by Veriff, our trusted KYC provider
        </div>
      </div>
    </div>
  );
}